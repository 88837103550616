import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useCall, useContractFunction } from '@usedapp/core';
import mintABI from '../abi/SmartContract.json';
import { SmartContract } from '../gen/types';

const mintInterface = new utils.Interface(mintABI);

const contractAddr: {
    [key: string]: {
        [ind: number]: string
    }
} = {
    "minter": {
        1: "0x7634854C5f6c52Bd29D1c1b695C56EFd72C62DF9",
        5: "0x89F84654cFF5908A3941a76C6D56710646BAA26e"
    }
}


export const useTotalSupply = (chainId: number) => {
    const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;
    const { value, error } = useCall({ contract: contract, method: 'totalSupply', args: [] }) ?? {};

    if (error) {
        console.error(error.message);
        return undefined;
    }

    return value?.[0];
}

export const useBalanceOf = (chainId: number,adress : string) => {
    const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;
    const { value, error } = useCall({ contract: contract, method: 'balanceOf', args: [adress] }) ?? {};

    if (error) {
        console.error(error.message);
        return undefined;
    }

    return value?.[0];
}
export const useGetPrice = (chainId: number) => {
    const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;
    const { value, error } = useCall({ contract: contract, method: 'price', args: [] }) ?? {};

    if (error) {
        console.error(error.message);
        return undefined;
    }

    return value?.[0];
}

export const useGetMaxSupply = (chainId: number) => {
    const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;
    const { value, error } = useCall({ contract: contract, method: 'maxSupply', args: [] }) ?? {};

    if (error) {
        console.error(error.message);
        return undefined;
    }

    return value?.[0];
}


//NOT RELEVANT
// export const useMaxSupply = (chainId: number) => {
//     const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;
//     const { value, error } = useCall({ contract: contract, method: 'maxSupply', args: [] }) ?? {};

//     if (error) {
//         console.error(error.message);
//         return undefined;
//     }

//     return value?.[0];
// }

export function useWLMintOrPublicMint(chainId: number) {
    const contract = new Contract(contractAddr.minter[chainId || 1], mintInterface) as SmartContract;

    const { state, send } = useContractFunction(contract, "mint", { transactionName: "Mint DK" });
    return { state, send };
}





