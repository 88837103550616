import styled from 'styled-components';

export const View = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 720px) {
        flex-direction: column;
    }

`

export const Page = styled(View)`
    width: 100vw;
    height: 100vh;
    background: black;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`