import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Config, DAppProvider, Mainnet,Goerli } from '@usedapp/core';

const config: Config = {
  networks: [Mainnet,Goerli],
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: "https://eth-mainnet.g.alchemy.com/v2/Y90i7RiTjuw9-K76Gahn-Pv6ypuYb1Is",
    [Goerli.chainId]: "https://eth-goerli.g.alchemy.com/v2/qWI0T-FTwMAHYwQXpyycDbT0_r2dG7cc"
  }
}

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
