import styled from 'styled-components';

export const Button = styled.button`
    // background-color: #252525;
     color: #white;
    // fill: white;
    // border: solid 2px #fcc583;
    // border-radius : 20px;
    // box-shadow: 6px 6px 0 black;
    // //clip-path: polygon(10% 10%,100% 10%,100% 50%,90% 90%,0 90%,0 50%);
     margin: 0px 5px;
     padding: 15px ;
    // width: 70%;
    font-size: 150%;
    // transition: all 0.3s ease-in-out;
    // cursor: pointer;
        width: 60%;
        border: solid 2px white;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;


    &:before{
        content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    }

    &:active{
        color: #000
    }

    &:active:after {
        background: transparent;
    }
    
    &:hover:before {
        opacity: 1;
    }
    
    &:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        left: 0;
        top: 0;
        border-radius: 10px;
    }
    
    @keyframes glowing {
        0% { background-position: 0 0; }
        50% { background-position: 400% 0; }
        100% { background-position: 0 0; }
    }
    }
    &:hover {
        background-color: black;
        box-shadow: none;
        color: #ffd39e;
        fill: #ffd39e;
    }
    
    &:hover > svg {
        color: #ffd39e;
        fill:  #ffd39e;
    }

    @media (max-width: 720px) {
        padding-left : 0px;
        padding-right : 0px;
    }
`