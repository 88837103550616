import { TransactionState } from "@usedapp/core"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { Button } from "../Button"
import { View } from "../Containers"
import mintVideo from './video/minting.mp4';

const Overlay = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #444444c9;
    backdrop-filter: blur(16px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
`

const Container = styled(View)`
    position: relative;
    background: #000000c9;
    align-items: center;
    justify-content: center;
    padding: 24px;
    border-radius: 24px;
    width: 25vw;
    height: auto;
    flex-direction: column;

    @media (max-width: 1200px) {
        width: 60vw;
    }

    @media (max-width: 720px) {
        width: 90vw;
    }
`

const Player = styled.video`
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 24px 48px #000000c9;
`

export const Video: React.FC<{ src: string, isMuted: boolean }> = ({ src, isMuted }) => {
    const refVideo = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!refVideo.current) {
            return;
        }

        if (isMuted) {
            //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
            refVideo.current.defaultMuted = true;
            refVideo.current.muted = true;
        }

        refVideo.current.src = src;
        refVideo.current.play();
    }, [src, isMuted]);

    return (
        <Player
            ref={refVideo}
            src={src}
            autoPlay
            preload="auto"
            loop
            controls={false}
            playsInline //FIX iOS black screen
        />
    );
}

export const TransactionStatus: React.FC<{ status: TransactionState }> = (props) => {
    return (
        <>
            {
                props.status === "Mining" &&
                <Overlay>
                    <Container>
                        {/* <Video src={mintVideo} isMuted /> */}
                        <h5 style={{ marginTop: 0 }}>Minting...</h5>
                        <p>Please wait...</p>
                    </Container>
                </Overlay>
            }
            {
                props.status === "Success" && 
                <Overlay>
                    <Container>
                        <Video src={mintVideo} isMuted />
                        <h5 style={{ marginTop: 30 }}>Minting successfull !</h5>
                        <Button style={{marginTop:13}} onClick={() => window.location.reload()}>Thanks !</Button>
                    </Container>
                </Overlay>
            }
        </>
    )
}