import { useEthers } from '@usedapp/core';
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button } from './components/Button';
import { Page, View } from './components/Containers';
import { Navbar } from './components/Navbar';
import { ReactComponent as Logo } from './images/logo.svg';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import { ethers, utils } from 'ethers';
import { useBalanceOf,useTotalSupply, useWLMintOrPublicMint,useGetPrice, useGetMaxSupply } from './hooks';
import { TransactionStatus } from './components/TransactionStatus';
import { Video } from './components/TransactionStatus';
import tokenVideo from './components/TransactionStatus/video/minting.mp4';
import './App.css';



const Animate1 = keyframes`
0% {
  transform: translateX(-100%);
}
100% {
  transform: translateX(100%);
}
`
const Animate2 = keyframes`
0% {
  transform: translateY(-100%);
}
100% {
  transform: translateY(100%);
}
`
const Animate3 = keyframes`
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}
`
const Animate4 = keyframes`
0% {
  transform: translateY(100%);
}
100% {
  transform: translateY(-100%);
}
`


const EnterLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-40%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`

const EnterRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(40%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`

const Stripe = styled.div`
  position: absolute;
  background: repeating-linear-gradient(
    -45deg,
    #000000ab,
    #000000ab 40%,
    #1a1a1a 40%,
    #1a1a1a 80%,
    #000000ab 80%,
    #000000ab 100%
  );

  @media (max-width: 720px) {
    background: repeating-linear-gradient(
      -45deg,
      #000000,
      #000000 22%,
      #1a1a1a 22%,
      #1a1a1a 70%,
      #000000 70%,
      #000000 100%
    );
  }

  mix-blend-mode: multiply;
  opacity: 0.9;
  width: 100vw;
  height: 100%;
  z-index: 0;
`

const Footer = styled(View)`
  width: 100vw;
  height: 4vh;
  background: black;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;

  @media (max-width: 720px) {
    display: flex;
  }
`;

const Background = styled(View)`
  background: url('images/background.jpg');
  background-size: cover;
  background-position: center 30%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
`

const Content = styled(View)`
  flex-direction: row;
  align-items: center;
  z-index: 2;
  
  & > div {
    margin: 0px 64px;
  }
  
  @media (max-width: 720px) {
    //margin-top: 2px;
    flex-direction: column-reverse;
    & > div {
      margin: 0px;
    }
  }
`

const Column = styled(View)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateX(-100%);
  animation: ${EnterLeft} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;

  & > p {
    transform: translateY(-80%);
  }

  @media (max-width: 720px) {
    svg * {
      width:60%;
      height:60%;
      transform : translateX(20%);
      padding-top : 0px !important;
    }
    

  }
`

const NFTContainer = styled(View)`
  padding: 0.2vw;
  padding: 0.2vw;
  width: 25vw;
  height: 25vw;
  background: black;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 16px 32px rgba(10, 10, 10, 0.5);
  opacity: 0;
  animation: ${EnterRight} 1s 0.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;

  @media (max-width: 720px) {
    padding: 0.3vw;
    width: 50vw;
    height: 50vw;
    margin-top: 25px !important;
    
  }
`;

const BorderNFTContainer = styled.span`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 4px;
background: linear-gradient(to right, #87878ee6, #87878ee6);
animation: ${Animate1} 4s linear infinite;
  
`;
const BorderNFTContainer2 = styled.span`
position: absolute;
top: 0;
right: 0;
width: 2px;
height: 100%;
background: linear-gradient(to bottom,#87878ee6,#87878ee6);
animation: ${Animate2} 4s linear infinite;
animation-delay: 2s;
  
`;
const BorderNFTContainer3 = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left,#87878ee6,#87878ee6);
  animation: ${Animate3} 4s linear infinite;
  
`;

const BorderNFTContainer4 = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(to top,#87878ee6,#87878ee6);
    animation: ${Animate4} 4s linear infinite;
    animation-delay: 2s;
  
`;


const CounterParaph = styled.p`
  border-bottom: 4px solid #f8b761;
  padding: 8px 64px;
  border-radius: 8px;
  font-size: 150%;
  font-weight: bolder;
`;

const CounterButton = styled(Button)`
  font-size: 150%;
  padding-left: 25px; 
  padding-right: 25px;
  //clip-path: polygon(0% 10%,100% 10%,100% 70%,75% 90%,0 90%,0 50%);
  margin: 0px 24px;

  @media (max-width: 720px) {
    transform : scale(0.8);
  }


`

const CounterView = styled(View)`
  flex-direction: row;
`

export const Counter: React.FC<{ onValueChanged: (value: number) => void, max?: number }> = (props) => {
  const [value, setValue] = useState(1);

  return (
    <CounterView style={{ alignItems: 'center', justifyContent: 'center' }}>
      <CounterButton onClick={() => {
        if (value > 1) {
          props.onValueChanged(value - 1);
          setValue(value - 1);
        }
      }}>-</CounterButton>

      <CounterParaph style={{ marginLeft: 16, marginRight: 16 }}>{value}</CounterParaph>

      <CounterButton onClick={() => {
        if (value < (props.max || 1)) {
          props.onValueChanged(value + 1);
          setValue(value + 1);
        }
      }}>+</CounterButton>
    </CounterView>
  )
}

const Pulse = keyframes`
  0% {
    filter: brightness(1);
    transform: scale(1.0);
  }
  25% {
    filter: brightness(1.1);
    transform: scale(1.05);
  }
  50% {
    filter: brightness(1);
    transform: scale(1.0);
  }
  100% {
    transform: scale(1);
  }
`

const MintBorder = styled(View)`
  background: white;
  padding-left: 0px;
  //clip-path: polygon(10% 10%,100% 10%,100% 50%,90% 90%,0 90%,0 50%);
  animation: ${Pulse} 1.2s cubic-bezier(0.77, 0, 0.175, 1) forwards infinite;
  margin-top: 32px;
  border-radius : 14px;

  &:hover {
    background: #f3abc8;
  }

  @media (max-width: 720px) {
    margin-top : 2.7vh;
    margin-right:8vw;
    width:25%;
    
  }
`

const MintButton = styled(Button)`
  font-size: 180%;
  width:100%;
  padding: 15px 30px 15px 30px;
  border-radius:14px;
  margin : 0px;

  @media (max-width: 720px) {
    padding: 10px;
  }

`

const ContainerLogo = styled.div`
  display :flex;
  justify-content : center;
  align-items : center;

  @media (max-width: 720px) {
    margin-bottom: -45px;
  }
  

`



function App() {
  const [amount, setAmount] = useState(1);
  const { account, activate, chainId } = useEthers();
  const { state: mintState, send: whiteListOrPublicMint } = useWLMintOrPublicMint(chainId!);
  const totalSupply = useTotalSupply(chainId!);
  const price = useGetPrice(chainId!);
  const maxSupply = useGetMaxSupply(chainId!)
  const balance = useBalanceOf(chainId!,account!)?.toNumber();
  const [convertedPrice, setConvertedPrice] = useState<number>(0)
  

  const onConnect = async () => {
    try {
      const providerOptions = {
        injected: {
          display: {
            name: 'Metamask',
            description: 'Connect with the provider in your Browser',
          },
          package: null,
        },
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              1: "https://eth-mainnet.g.alchemy.com/v2/Y90i7RiTjuw9-K76Gahn-Pv6ypuYb1Is",
              5: "https://eth-goerli.g.alchemy.com/v2/qWI0T-FTwMAHYwQXpyycDbT0_r2dG7cc"
            }
          },
        },
      }

      const web3Modal = new Web3Modal({
        providerOptions,
      });

      const provider = await web3Modal.connect();
      await activate(provider);
      
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    console.log('🔗 Chain ID : ', chainId);
    console.log('👤 Account : ', account);
    console.log('Balance : ', balance);
    console.log('Price: ',price)
    // console.log('Converted Price: ',convertedPrice)
    console.log('❇️ Supply progression : ', totalSupply?.toNumber(), '/', maxSupply);
    if(price !== undefined){
      const convertedPrice = parseFloat(ethers.utils.formatEther(price!.toString()))
      console.log("Converted Price From Wei To ETH: ",convertedPrice)
      setConvertedPrice(convertedPrice)
    }
 
  }, [account, chainId, totalSupply, maxSupply,balance,price])


  

  useEffect(() => {
    if (mintState.status === "Exception" || mintState.status === 'Fail') {
      alert(mintState.errorMessage);
    }
  }, [mintState])

  // const getTransactionStatus = useCallback(() => {
  //   if (saleConfig?.portalAccessPrice && parseFloat(ethers.utils.formatEther(saleConfig?.portalAccessPrice.toString())) > 0) {
  //     return wlmint1State.status;
  //   }

  //   if (saleConfig?.reserveCorpPrice && parseFloat(ethers.utils.formatEther(saleConfig?.reserveCorpPrice.toString())) > 0) {
  //     return wlmint2State.status;
  //   }

  //   if (saleConfig?.publicRegistrationPrice && parseFloat(ethers.utils.formatEther(saleConfig?.publicRegistrationPrice.toString())) > 0) {
  //     return wlmint3State.status;
  //   }

  //   if (saleConfig?.publicPrice && parseFloat(ethers.utils.formatEther(saleConfig?.publicPrice.toString())) > 0) {
  //     return mintState.status;
  //   }

  //   return "None";
  // }, [wlmint1State, wlmint2State, wlmint3State, mintState, saleConfig?.portalAccessPrice, saleConfig?.reserveCorpPrice, saleConfig?.publicRegistrationPrice, saleConfig?.publicPrice]);

  const onMintClicked = async () => {
    console.log('Mint params :', account, amount);
    if (account) {
        fetch(`https://whitelist-dk.herokuapp.com/verifyWhitelist/${account}`).then(async (value) => {
          try {
            const proof = await value.json();
            console.log(proof)

            if(proof) {
                //debugger;
                await whiteListOrPublicMint(amount,proof,account,{ value: utils.parseEther((amount * (parseFloat(ethers.utils.formatEther(price!.toString())))).toString()).toString() });
                return;
            }
            //alert("You're not whitelisted !");
            return;
          }
          catch {
            alert("Mint not authorized !");
          }

        });
    }
    return;
  }

  return (
    
    <Page style={{ background: 'rgba(255, 0, 0, 0.2)', flexDirection: 'column', height: '100%', position: 'relative' }}>
      <TransactionStatus status={mintState.status || "None"} />
      <Background>
        <Stripe />
      </Background>
      <Navbar />
      <Content>
        <Column>
          <ContainerLogo style={{marginTop:'20px'}}>
            <Logo style={{}} />
          </ContainerLogo>
          {
            account && 
            <>
              <h1 style={{fontFamily: 'Circular',paddingBottom:'6px' }}>{totalSupply?.toNumber() ?? 0} <span style={{ color: '#f8b761', fontFamily: 'Circular' }}>/</span> {maxSupply?.toNumber() ?? 0}</h1>
              <p>{amount} DK Cost {amount * convertedPrice} ETH</p>
              <Counter max={5} onValueChanged={(value) => {
                setAmount(value);
              }} />
            </>
          }
          {
            account ?
              <MintBorder>
                <MintButton onClick={onMintClicked} style={{}}>MINT NOW</MintButton>
              </MintBorder>
              :
              <Button onClick={onConnect} style={{}}>CONNECT WALLET</Button>
          }
        </Column>
        <NFTContainer>
          <BorderNFTContainer/>
          <BorderNFTContainer2/>
          <BorderNFTContainer3/>
          <BorderNFTContainer4/>
          {/* <NFTReflection /> */}
          {/* <NFTImage src={process.env.PUBLIC_URL + "images/16.jpg"} /> */}
          <Video src={tokenVideo} isMuted />
        </NFTContainer>
      </Content>
      <Footer>
        <p>
          David Kassman Copyright © - 2023
        </p>
      </Footer>
    </Page>
  );
}

export default App;