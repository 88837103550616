import { useState } from "react"
import styled from "styled-components"
import { Button } from "../Button"
import { View } from "../Containers"
import { ReactComponent as Twitter } from './icons/twitter.svg';
import { ReactComponent as Instagram } from './icons/instagram.svg';
import { ReactComponent as Discord } from './icons/discord.svg';
import { ReactComponent as Opensea } from './icons/opensea.svg';
import { ReactComponent as Website } from './icons/website.svg';
import { ReactComponent as Menu } from './icons/menu.svg';
import { ReactComponent as Spatial } from './icons/spatial.svg';


const NavbarContainer = styled(View) <{ opaque: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4vw;
    height: 10vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    backdrop-filter: ${props => props.opaque ? "blur(16px)" : "blur(0px)"};
    background: ${props => props.opaque ? "#000" : "transparent"};
    transition: all 0.3s ease-in-out;
    z-index: 10;
    
    @media (max-width: 720px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        background: ${props => props.opaque ? "#000" : "transparent"};
    }
`

const Logo = styled.img`
    margin: 16px 0px;
    height: 25%;
    width:100%;

    @media (max-width: 720px) {
        margin: 16px 0px;
        width: 50%;
    }
`

const NavbarEntryRounded = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: 1px solid white;
    outline: none;
    padding: 24px 48px;
    background: transparent;
    color: white;
    margin: 1.5% 0px;
    font-family: 'Inter-Light';
    font-size: 130%;
    transition: all 0.35s cubic-bezier(0.77, 0, 0.175, 1);
    

    @media (max-width: 720px) {
        margin-bottom: 24px;
    }

    &:hover {
        background: white;
        color: black;
        cursor: pointer;
    }

    &:active {
        opacity: 0.6;
    }
`

const NavbarButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    //clip-path: polygon(30% 20%,100% 20%,100% 50%,70% 80%,0 80%,0 50%);
    font-size: 1.1em;
    padding: 10px 18px;
    box-shadow:none;

    &:hover {
        box-shadow: 0 3px 3px #f1d7b5;

    }

    @media (max-width: 720px) {
        & svg {
            width: 1.7em;
            height: 1.6em;
        }
    }
`;

const NavbarMenu = styled(View) <{ opened: boolean }>`
    flex-direction: row;
    height: 50%;

    @media (max-width: 720px) {
        flex-direction: row;
        padding-bottom: 8px;
        align-items: center;
        justify-content: center;

        & ${NavbarButton} {
            display: ${p => p.opened ? "flex" : "none"};
        }

        & ${NavbarEntryRounded} {
            display: ${p => p.opened ? "flex" : "none"};
        }
    }
`

const MenuButton = styled.div`
    display: none;
    appearance: none;
    border: none;
    outline: none;
    margin-left: 24px;
    width: 48px;
    height: 48px;

    @media (max-width: 720px) {
        display: block;
        width: 32px;
        height: 32px;
    }
`

const Row = styled(View)`
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;

    @media (max-width: 720px) {
        justify-content: space-between;
        padding: 12px 12px;
    }
`;

export const Navbar = () => {
    const [opened, setOpened] = useState(false);

    return (
        <NavbarContainer opaque={true}>
            <Row>
                <Logo src={process.env.PUBLIC_URL + "images/logo.png"} />
                <MenuButton onClick={() => {
                    setOpened(!opened);
                }}><Menu /></MenuButton>
            </Row>
            <NavbarMenu opened={opened}>
                <NavbarButton onClick={() => window.open('https://davidkassman.com/')}>
                    <Website fill="red" />
                </NavbarButton>
                <NavbarButton onClick={() => window.open('https://www.spatial.io/s/David-63146c02c49050000147d782?share=3302013915264006458')}>
                    <Spatial fill="currentColor" />
                </NavbarButton>
                <NavbarButton onClick={() => window.open('https://www.instagram.com/davidkassman/')}>
                    <Instagram fill="currentColor" />
                </NavbarButton>
                <NavbarButton onClick={() => window.open('https://discord.gg/ygqvs3FnDu')}>
                    <Discord fill="currentColor" />
                </NavbarButton>
                <NavbarButton onClick={() => window.open('https://opensea.io/collection/davidkassmannft')}>
                    <Opensea fill="currentColor" />
                </NavbarButton>
            </NavbarMenu>
        </NavbarContainer>
    )
}